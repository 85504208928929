import React, { useState, useEffect } from "react";
import { Shield, Users, Key, FileText, UserCog, Copy, Check } from "lucide-react";
import { fetchUsers, fetchUserLeads, makeAdmin, deleteUser } from "../services/api";

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState('users');
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [userLeads, setUserLeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [copiedKey, setCopiedKey] = useState(null);

    useEffect(() => {
        loadUsers();
    }, []);

    useEffect(() => {
        if (selectedUser && activeTab === 'leads') {
            loadUserLeads(selectedUser._id);
        }
    }, [selectedUser, activeTab]);

    const loadUsers = async () => {
        try {
            setLoading(true);
            setError("");
            const response = await fetchUsers();
            if (!response || !Array.isArray(response)) {
                throw new Error("Ошибка: `fetchUsers()` не вернул массив!");
            }
            setUsers(response);
        } catch (err) {
            setError(err.message || "Ошибка загрузки пользователей");
        } finally {
            setLoading(false);
        }
    };

    const loadUserLeads = async (userId) => {
        try {
            setLoading(true);
            setError("");
            const response = await fetchUserLeads(userId);
            if (!response || !Array.isArray(response)) {
                throw new Error("Ошибка: `fetchUserLeads()` не вернул массив!");
            }
            setUserLeads(response);
        } catch (err) {
            setError(err.message || "Ошибка загрузки лидов пользователя");
        } finally {
            setLoading(false);
        }
    };

    const handleViewLeads = (user) => {
        setSelectedUser(user);
        setActiveTab('leads');
    };

    const handleCopyApiKey = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        setCopiedKey(apiKey);
        setTimeout(() => setCopiedKey(null), 2000);
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-900 via-purple-900 to-blue-900">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 animate-pulse"></div>
            
            <div className="relative p-6">
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center space-x-4">
                        <Shield className="w-12 h-12 text-blue-400" />
                        <div>
                            <h1 className="text-3xl font-bold text-white">Админ Панель</h1>
                            <p className="text-blue-300">Управление системой</p>
                        </div>
                    </div>
                </div>

                {/* Tabs */}
                <div className="flex space-x-4 mb-6">
                    <button
                        onClick={() => setActiveTab('users')}
                        className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-all duration-200 ${
                            activeTab === 'users' 
                                ? 'bg-white/20 text-white' 
                                : 'bg-white/5 text-blue-300 hover:bg-white/10'
                        }`}
                    >
                        <Users className="w-5 h-5" />
                        <span>Пользователи</span>
                    </button>
                    <button
                        onClick={() => setActiveTab('api')}
                        className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-all duration-200 ${
                            activeTab === 'api' 
                                ? 'bg-white/20 text-white' 
                                : 'bg-white/5 text-blue-300 hover:bg-white/10'
                        }`}
                    >
                        <Key className="w-5 h-5" />
                        <span>API Ключи</span>
                    </button>
                    {selectedUser && (
                        <button
                            onClick={() => setActiveTab('leads')}
                            className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-all duration-200 ${
                                activeTab === 'leads' 
                                    ? 'bg-white/20 text-white' 
                                    : 'bg-white/5 text-blue-300 hover:bg-white/10'
                            }`}
                        >
                            <FileText className="w-5 h-5" />
                            <span>Лиды {selectedUser.username}</span>
                        </button>
                    )}
                </div>

                {loading ? (
                    <div className="flex justify-center">
                        <div className="bg-white/10 backdrop-blur-lg rounded-lg p-4">
                            <p className="text-blue-300">Загрузка...</p>
                        </div>
                    </div>
                ) : error ? (
                    <div className="bg-red-500/10 backdrop-blur-lg rounded-lg p-4">
                        <p className="text-red-400">{error}</p>
                    </div>
                ) : (
                    <div className="bg-white/10 backdrop-blur-lg rounded-lg overflow-hidden">
                        {activeTab === 'users' && (
                            <UsersTable 
                                users={users}
                                handleMakeAdmin={makeAdmin}
                                handleDeleteUser={deleteUser}
                                handleViewLeads={handleViewLeads}
                            />
                        )}
                        {activeTab === 'api' && (
                            <ApiKeysTable 
                                users={users}
                                onCopyKey={handleCopyApiKey}
                                copiedKey={copiedKey}
                            />
                        )}
                        {activeTab === 'leads' && selectedUser && (
                            <div>
                                <div className="p-4 border-b border-white/10 bg-black/20">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-2">
                                            <span className="text-white">Лиды пользователя:</span>
                                            <span className="text-blue-300 font-medium">{selectedUser.username}</span>
                                            <span className={`px-2 py-1 rounded-full text-xs ${
                                                selectedUser.role === 'admin' 
                                                    ? 'bg-purple-500/20 text-purple-300'
                                                    : 'bg-blue-500/20 text-blue-300'
                                            }`}>
                                                {selectedUser.role}
                                            </span>
                                        </div>
                                        <div className="text-blue-300">
                                            Всего лидов: {userLeads.length}
                                        </div>
                                    </div>
                                </div>
                                <UserLeadsTable leads={userLeads} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const UsersTable = ({ users, handleMakeAdmin, handleDeleteUser, handleViewLeads }) => (
    <table className="w-full">
        <thead>
            <tr className="bg-black/20">
                <th className="p-4 text-left text-blue-300 font-medium">ID</th>
                <th className="p-4 text-left text-blue-300 font-medium">Имя</th>
                <th className="p-4 text-left text-blue-300 font-medium">Email</th>
                <th className="p-4 text-left text-blue-300 font-medium">Роль</th>
                <th className="p-4 text-left text-blue-300 font-medium">Действия</th>
            </tr>
        </thead>
        <tbody className="divide-y divide-white/10">
            {users.map(user => (
                <tr key={user._id} className="group hover:bg-white/5 transition-colors">
                    <td className="p-4 text-white/80">{user._id}</td>
                    <td className="p-4 text-white/80">{user.username}</td>
                    <td className="p-4 text-white/80">{user.email || "Нет email"}</td>
                    <td className="p-4">
                        <span className={`px-2 py-1 rounded-full text-sm ${
                            user.role === 'admin' 
                                ? 'bg-purple-500/20 text-purple-300'
                                : 'bg-blue-500/20 text-blue-300'
                        }`}>
                            {user.role}
                        </span>
                    </td>
                    <td className="p-4">
                        <div className="flex space-x-2">
                            {user.role !== "admin" && (
                                <button 
                                    className="flex items-center space-x-1 px-3 py-1 rounded-lg
                                             bg-gradient-to-r from-purple-500/80 to-blue-500/80 
                                             hover:from-purple-500 hover:to-blue-500
                                             text-white transition-all duration-200"
                                    onClick={() => handleMakeAdmin(user._id)}
                                >
                                    <UserCog className="w-4 h-4" />
                                    <span>Сделать админом</span>
                                </button>
                            )}
                            
                            <button 
                                className="flex items-center space-x-1 px-3 py-1 rounded-lg
                                         bg-gradient-to-r from-blue-500/80 to-purple-500/80
                                         hover:from-blue-500 hover:to-purple-500
                                         text-white transition-all duration-200"
                                onClick={() => handleViewLeads(user)}
                            >
                                <FileText className="w-4 h-4" />
                                <span>Лиды пользователя</span>
                            </button>
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
);

const UserLeadsTable = ({ leads }) => (
    <table className="w-full">
        <thead>
            <tr className="bg-black/20">
                <th className="p-4 text-left text-blue-300 font-medium">ID</th>
                <th className="p-4 text-left text-blue-300 font-medium">Имя</th>
                <th className="p-4 text-left text-blue-300 font-medium">Email</th>
                <th className="p-4 text-left text-blue-300 font-medium">Статус</th>
                <th className="p-4 text-left text-blue-300 font-medium">Дата создания</th>
            </tr>
        </thead>
        <tbody className="divide-y divide-white/10">
            {leads.length > 0 ? (
                leads.map(lead => (
                    <tr key={lead._id} className="group hover:bg-white/5 transition-colors">
                        <td className="p-4 text-white/80">{lead._id}</td>
                        <td className="p-4 text-white/80">{lead.name}</td>
                        <td className="p-4 text-white/80">{lead.email || "Нет email"}</td>
                        <td className="p-4">
                            <span className={`px-2 py-1 rounded-full text-sm ${
                                lead.status === 'new' ? 'bg-green-500/20 text-green-300' :
                                lead.status === 'in_progress' ? 'bg-blue-500/20 text-blue-300' :
                                lead.status === 'completed' ? 'bg-purple-500/20 text-purple-300' :
                                'bg-red-500/20 text-red-300'
                            }`}>
                                {lead.status}
                            </span>
                        </td>
                        <td className="p-4 text-white/80">
                            {new Date(lead.createdAt).toLocaleDateString()}
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan="5" className="p-4 text-center text-white/60">
                        У пользователя нет лидов
                    </td>
                </tr>
            )}
        </tbody>
    </table>
);

const ApiKeysTable = ({ users, onCopyKey, copiedKey }) => (
    <table className="w-full">
        <thead>
            <tr className="bg-black/20">
                <th className="p-4 text-left text-blue-300 font-medium">Пользователь</th>
                <th className="p-4 text-left text-blue-300 font-medium">API Ключ</th>
                <th className="p-4 text-left text-blue-300 font-medium">Статус</th>
                <th className="p-4 text-left text-blue-300 font-medium">Действия</th>
            </tr>
        </thead>
        <tbody className="divide-y divide-white/10">
            {users.map(user => (
                <tr key={user._id} className="group hover:bg-white/5 transition-colors">
                    <td className="p-4">
                        <div className="flex items-center space-x-2">
                            <span className="text-white/80">{user.username}</span>
                            <span className={`px-2 py-1 rounded-full text-xs ${
                                user.role === 'admin' 
                                    ? 'bg-purple-500/20 text-purple-300'
                                    : 'bg-blue-500/20 text-blue-300'
                            }`}>
                                {user.role}
                            </span>
                        </div>
                    </td>
                    <td className="p-4">
                        <code className="text-white/80 bg-black/20 px-2 py-1 rounded">
                            {user.apiKey || 'Нет ключа'}
                        </code>
                    </td>
                    <td className="p-4">
                        <span className={`px-2 py-1 rounded-full text-sm ${
                            user.apiKey 
                                ? 'bg-green-500/20 text-green-300'
                                : 'bg-red-500/20 text-red-300'
                        }`}>
                            {user.apiKey ? 'Активен' : 'Не активен'}
                        </span>
                    </td>
                    <td className="p-4">
                        {user.apiKey && (
                            <button
                                onClick={() => onCopyKey(user.apiKey)}
                                className="flex items-center space-x-1 px-3 py-1 rounded-lg
                                         bg-white/10 hover:bg-white/20
                                         text-blue-300 transition-all duration-200"
                            >
                                {copiedKey === user.apiKey ? (
                                    <>
                                        <Check className="w-4 h-4 text-green-400" />
                                        <span className="text-green-400">Скопировано</span>
                                    </>
                                ) : (
                                    <>
                                        <Copy className="w-4 h-4" />
                                        <span>Копировать</span>
                                    </>
                                )}
                            </button>
                        )}
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default AdminDashboard;