import React from "react";
import { FileJson, Send, Users } from "lucide-react";

const Integration = () => {
    const apiKey = localStorage.getItem("apiKey") || "YOUR_API_KEY";
    const baseUrl = "https://trust-solution.com/api/leads";

    const EndpointCard = ({ method, endpoint, headers, body, response }) => (
        <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6 mb-8">
            <div className="flex items-center gap-4 mb-6">
                <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-3 rounded-lg">
                    <FileJson className="w-6 h-6 text-white" />
                </div>
                <div>
                    <div className="flex items-center gap-3">
                        <span className={`px-3 py-1 rounded-full text-sm ${
                            method === "POST" ? "bg-yellow-500/20 text-yellow-400" : "bg-green-500/20 text-green-400"
                        }`}>
                            {method}
                        </span>
                        <code className="text-blue-300">{endpoint}</code>
                    </div>
                </div>
            </div>

            <div className="space-y-4">
                <div>
                    <h3 className="text-blue-300 text-sm mb-2">Заголовки запроса:</h3>
                    <pre className="bg-slate-800/50 backdrop-blur-sm p-4 rounded-lg overflow-x-auto">
                        {JSON.stringify({
                            "api-key": apiKey,
                            "Content-Type": "application/json"
                        }, null, 2)}
                    </pre>
                </div>

                {body && (
                    <div>
                        <h3 className="text-blue-300 text-sm mb-2">Тело запроса:</h3>
                        <pre className="bg-slate-800/50 backdrop-blur-sm p-4 rounded-lg overflow-x-auto">
                            {JSON.stringify(body, null, 2)}
                        </pre>
                    </div>
                )}

                <div>
                    <h3 className="text-blue-300 text-sm mb-2">Пример ответа:</h3>
                    <pre className="bg-slate-800/50 backdrop-blur-sm p-4 rounded-lg overflow-x-auto">
                        {JSON.stringify(response, null, 2)}
                    </pre>
                </div>
            </div>
        </div>
    );

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-900 via-purple-900 to-blue-900 p-6 pt-24">
            <div className="max-w-5xl mx-auto">
                <div className="mb-8">
                    <h1 className="text-3xl font-bold text-white mb-2">Документация по интеграции с API лидов</h1>
                    <p className="text-blue-300">Руководство по использованию API для работы с лидами</p>
                </div>

                <section>
                    <h2 className="text-2xl font-semibold text-white mb-6 flex items-center gap-3">
                        <Send className="w-6 h-6" />
                        Создание лида
                    </h2>
                    <EndpointCard
                        method="POST"
                        endpoint={`${baseUrl}/create`}
                        body={{
                            firstName: "John",
                            lastName: "Doe",
                            email: "john.doe@example.com",
                            phone: "+48123456789",
                            country: "Poland",
                            ip_address: "192.168.1.1"
                        }}
                        response={{
                            message: "Lead created successfully and sent to CRM",
                            lead: {
                                firstName: "John",
                                lastName: "Doe",
                                email: "john.doe@example.com",
                                phone: "+48123456789",
                                country: "Poland",
                                ip_address: "192.168.1.1",
                                loginUrl: "https://example.com/login",
                                externalId: "123456",
                                status: "pending",
                                user: "userid123",
                                _id: "leadid123",
                                createdAt: "2025-01-15T08:00:00Z",
                                updatedAt: "2025-01-15T08:00:00Z"
                            }
                        }}
                    />
                </section>

                <section>
                    <h2 className="text-2xl font-semibold text-white mb-6 flex items-center gap-3">
                        <Users className="w-6 h-6" />
                        Получение лидов
                    </h2>
                    <EndpointCard
                        method="GET"
                        endpoint={`${baseUrl}/?deposited=true&from=1736899200&to=1736985600`}
                        response={{
                            message: "Leads retrieved successfully",
                            leads: [{
                                id: "leadid123",
                                email: "john.doe@example.com",
                                phone: "+48123456789",
                                status: "approved",
                                deposited: true
                            }]
                        }}
                    />
                </section>
            </div>
        </div>
    );
};

export default Integration;