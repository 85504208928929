import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Shield, Lock, User, Mail, MessageSquare, ArrowRight } from "lucide-react";

const Register = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    tg: ""
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (formData.username.length < 2) {
      newErrors.username = "Введите имя пользователя";
    }
    if (formData.password.length < 6) {
      newErrors.password = "Пароль должен быть не менее 6 символов";
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Введите корректный email адрес";
    }
    if (!formData.tg) {
      newErrors.tg = "Введите имя пользователя Telegram";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await fetch("https://trust-solution.com/api/users/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || "Ошибка регистрации");

      if (data.apiKey) {
        localStorage.setItem("apiKey", data.apiKey);
      }

      navigate("/login");
    } catch (error) {
      setErrors({ general: error.message });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-blue-900 via-purple-900 to-blue-900 p-4">
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 animate-pulse"></div>
      
      <div className="relative w-full max-w-md">
        <div className="flex flex-col items-center mb-8">
          <Shield className="w-16 h-16 text-blue-400 mb-4" />
          <h1 className="text-3xl font-bold text-white mb-2">Регистрация</h1>
        </div>

        <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 shadow-2xl">
          {errors.general && <p className="text-red-400 text-center mb-4">{errors.general}</p>}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <User className="absolute left-3 top-3.5 w-5 h-5 text-blue-300" />
              <input
                type="text"
                name="username"
                placeholder="Имя пользователя"
                value={formData.username}
                onChange={handleChange}
                className="w-full bg-white/10 border border-blue-300/20 text-white placeholder-blue-300/50 
                         pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400/50 
                         focus:border-transparent transition-all duration-200"
                required
              />
              {errors.username && <p className="text-red-400 text-sm mt-1">{errors.username}</p>}
            </div>

            <div className="relative">
              <Lock className="absolute left-3 top-3.5 w-5 h-5 text-blue-300" />
              <input
                type="password"
                name="password"
                placeholder="Пароль"
                value={formData.password}
                onChange={handleChange}
                className="w-full bg-white/10 border border-blue-300/20 text-white placeholder-blue-300/50 
                         pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400/50 
                         focus:border-transparent transition-all duration-200"
                required
              />
              {errors.password && <p className="text-red-400 text-sm mt-1">{errors.password}</p>}
            </div>

            <div className="relative">
              <Mail className="absolute left-3 top-3.5 w-5 h-5 text-blue-300" />
              <input
                type="email"
                name="email"
                placeholder="Email адрес"
                value={formData.email}
                onChange={handleChange}
                className="w-full bg-white/10 border border-blue-300/20 text-white placeholder-blue-300/50 
                         pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400/50 
                         focus:border-transparent transition-all duration-200"
                required
              />
              {errors.email && <p className="text-red-400 text-sm mt-1">{errors.email}</p>}
            </div>

            <div className="relative">
              <MessageSquare className="absolute left-3 top-3.5 w-5 h-5 text-blue-300" />
              <input
                type="text"
                name="tg"
                placeholder="Имя пользователя Telegram"
                value={formData.tg}
                onChange={handleChange}
                className="w-full bg-white/10 border border-blue-300/20 text-white placeholder-blue-300/50 
                         pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400/50 
                         focus:border-transparent transition-all duration-200"
                required
              />
              {errors.tg && <p className="text-red-400 text-sm mt-1">{errors.tg}</p>}
            </div>

            <button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 
                       text-white font-medium py-3 px-4 rounded-lg transition-all duration-200 
                       shadow-lg hover:shadow-blue-500/25 flex items-center justify-center space-x-2 
                       focus:outline-none focus:ring-2 focus:ring-blue-400/50"
            >
              <span>Зарегистрироваться</span>
              <ArrowRight className="w-5 h-5" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;