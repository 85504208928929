import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Shield, Sparkles, ChevronDown, Plug, Menu, X } from "lucide-react";

const Header = () => {
    const navigate = useNavigate();
    const isAuthenticated = !!localStorage.getItem("authToken");
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("userData");
        localStorage.removeItem("apiKey");

        navigate("/login", { replace: true });
        window.location.reload(); // Перезагрузка страницы для очистки кэша React
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-r from-blue-900 via-purple-900 to-blue-900">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 animate-pulse"></div>
            
            {/* Main Header */}
            <div className="relative px-4 md:px-6 py-4 flex justify-between items-center backdrop-blur-sm">
                <div className="flex items-center space-x-2">
                    <Shield className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />
                    <div>
                        <h1 className="text-xl md:text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                            Trust Solution
                        </h1>
                        <p className="text-[10px] md:text-xs text-blue-300">Your reliable partner</p>
                    </div>
                </div>

                {/* Mobile Menu Button */}
                <button 
                    onClick={toggleMenu}
                    className="md:hidden p-2 text-blue-300 hover:text-blue-200"
                >
                    {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
                </button>

                {/* Desktop Navigation */}
                <nav className="hidden md:flex items-center">
                    <ul className="flex items-center space-x-8">
                        {!isAuthenticated ? (
                            <>
                                <li>
                                    <Link 
                                        to="/login" 
                                        className="flex items-center space-x-1 text-blue-300 hover:text-blue-200 transition-colors duration-200"
                                    >
                                        <span>Войти</span>
                                        <Sparkles className="w-4 h-4" />
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to="/register" 
                                        className="bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 
                                                 px-6 py-2 rounded-full text-white font-medium transition-all duration-200 
                                                 shadow-lg hover:shadow-blue-500/25"
                                    >
                                        Регистрация
                                    </Link>
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <Link 
                                        to="/dashboard" 
                                        className="flex items-center space-x-2 text-blue-300 hover:text-blue-200 transition-colors duration-200"
                                    >
                                        <span>Личный кабинет</span>
                                        <ChevronDown className="w-4 h-4" />
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to="/leads" 
                                        className="flex items-center space-x-2 text-blue-300 hover:text-blue-200 transition-colors duration-200"
                                    >
                                        <span>Лиды</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to="/integration" 
                                        className="flex items-center space-x-2 text-blue-300 hover:text-blue-200 transition-colors duration-200"
                                    >
                                        <Plug className="w-5 h-5" />
                                        <span>Интеграция</span>
                                    </Link>
                                </li>
                                <li>
                                    <button 
                                        onClick={handleLogout} 
                                        className="bg-gradient-to-r from-red-500 to-pink-500 hover:from-red-600 hover:to-purple-600 
                                                 px-6 py-2 rounded-full text-white font-medium transition-all duration-200 
                                                 shadow-lg hover:shadow-red-500/25"
                                    >
                                        Выйти
                                    </button>
                                </li>
                            </>
                        )}
                    </ul>
                </nav>
            </div>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <div className="md:hidden absolute top-full left-0 right-0 bg-gradient-to-r from-blue-900 via-purple-900 to-blue-900 border-t border-blue-800/50">
                    <nav className="p-4">
                        <ul className="flex flex-col space-y-4">
                            {!isAuthenticated ? (
                                <>
                                    <li>
                                        <Link 
                                            to="/login" 
                                            onClick={() => setIsMenuOpen(false)}
                                            className="flex items-center space-x-2 text-blue-300 hover:text-blue-200 transition-colors duration-200"
                                        >
                                            <Sparkles className="w-4 h-4" />
                                            <span>Войти</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                            to="/register" 
                                            onClick={() => setIsMenuOpen(false)}
                                            className="inline-block bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 
                                                     px-6 py-2 rounded-full text-white font-medium transition-all duration-200 
                                                     shadow-lg hover:shadow-blue-500/25"
                                        >
                                            Регистрация
                                        </Link>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li>
                                        <Link 
                                            to="/dashboard" 
                                            onClick={() => setIsMenuOpen(false)}
                                            className="flex items-center space-x-2 text-blue-300 hover:text-blue-200 transition-colors duration-200"
                                        >
                                            <ChevronDown className="w-4 h-4" />
                                            <span>Личный кабинет</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                            to="/leads" 
                                            onClick={() => setIsMenuOpen(false)}
                                            className="flex items-center space-x-2 text-blue-300 hover:text-blue-200 transition-colors duration-200"
                                        >
                                            <span>Лиды</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                            to="/integration" 
                                            onClick={() => setIsMenuOpen(false)}
                                            className="flex items-center space-x-2 text-blue-300 hover:text-blue-200 transition-colors duration-200"
                                        >
                                            <Plug className="w-5 h-5" />
                                            <span>Интеграция</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <button 
                                            onClick={handleLogout}
                                            className="w-full bg-gradient-to-r from-red-500 to-pink-500 hover:from-red-600 hover:to-purple-600 
                                                     px-6 py-2 rounded-full text-white font-medium transition-all duration-200 
                                                     shadow-lg hover:shadow-red-500/25"
                                        >
                                            Выйти
                                        </button>
                                    </li>
                                </>
                            )}
                        </ul>
                    </nav>
                </div>
            )}
        </header>
    );
};

export default Header;