import React from "react";

const Footer = () => {
    return (
        <footer className="bg-gradient-to-r from-blue-900 via-purple-900 to-blue-900 relative">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10"></div>
            <div className="relative backdrop-blur-sm py-4">
                <p className="text-center text-blue-300 text-sm">
                    Trust Solution &copy; {new Date().getFullYear()}
                </p>
            </div>
        </footer>
    );
};

export default Footer;