import axios from "axios";

const API_URL = "https://trust-solution.com/api"; // Убедись, что это правильный сервер

// ✅ Функция для получения API-ключа (чтобы избежать ошибок)
const getApiKey = () => {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    if (!userData.apiKey) {
        console.error("❌ Ошибка: отсутствует API-ключ!");
        return null;
    }
    return userData.apiKey;
};

// ✅ Получение всех пользователей (только для админов)
export const fetchUsers = async () => {
    try {
        const apiKey = getApiKey();
        if (!apiKey) throw new Error("❌ Ошибка: отсутствует API-ключ!");

        console.log("📡 Запрос GET /admin/users с API-ключом:", apiKey);

        const response = await axios.get(`${API_URL}/admin/users`, {
            headers: { "api-key": apiKey },
        });

        console.log("✅ Получен список пользователей:", response.data);
        return response.data;
    } catch (error) {
        console.error("❌ Ошибка при загрузке пользователей:", error.response?.data || error.message);
        throw error;
    }
};

// ✅ Назначение пользователя админом
export const makeAdmin = async (userId) => {
    try {
        const apiKey = getApiKey();
        if (!apiKey) throw new Error("❌ Ошибка: отсутствует API-ключ!");

        console.log(`📡 Запрос PUT /admin/users/${userId}/make-admin с API-ключом:`, apiKey);

        const response = await axios.put(`${API_URL}/admin/users/${userId}/make-admin`, {}, {
            headers: { "api-key": apiKey },
        });

        console.log("✅ Пользователь назначен админом:", response.data);
        return response.data;
    } catch (error) {
        console.error("❌ Ошибка при назначении админа:", error.response?.data || error.message);
        throw error;
    }
};

// ✅ Удаление пользователя
export const deleteUser = async (userId) => {
    try {
        const apiKey = getApiKey();
        if (!apiKey) throw new Error("❌ Ошибка: отсутствует API-ключ!");

        console.log(`📡 Запрос DELETE /admin/users/${userId} с API-ключом:`, apiKey);

        const response = await axios.delete(`${API_URL}/admin/users/${userId}`, {
            headers: { "api-key": apiKey },
        });

        console.log("✅ Пользователь удален:", response.data);
        return response.data;
    } catch (error) {
        console.error("❌ Ошибка при удалении пользователя:", error.response?.data || error.message);
        throw error;
    }
};

export const fetchLeads = async () => {
    try {
        const apiKey = localStorage.getItem("apiKey");
        if (!apiKey) throw new Error("API-ключ отсутствует!");

        console.log("📡 Запрос GET /admin/leads с API-ключом:", apiKey);

        const response = await axios.get(`${API_URL}/admin/leads`, {
            headers: { "api-key": apiKey },
        });

        return response.data;
    } catch (error) {
        console.error("❌ Ошибка при загрузке лидов:", error.response?.data || error.message);
        throw error;
    }
};
export const fetchUserLeads = async (userId) => {
    try {
        const apiKey = localStorage.getItem("apiKey");
        if (!apiKey) throw new Error("API-ключ отсутствует!");

        console.log(`📡 Запрос GET /admin/users/${userId}/leads с API-ключом:`, apiKey);

        const response = await axios.get(`${API_URL}/admin/users/${userId}/leads`, {
            headers: { "api-key": apiKey },
        });

        return response.data;
    } catch (error) {
        console.error("❌ Ошибка при загрузке лидов пользователя:", error.response?.data || error.message);
        throw error;
    }
};

