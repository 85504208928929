import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Leads from "./pages/Leads";
import Integration from "./pages/Integration";
import AdminDashboard from "./pages/Dash";
import Footer from "./components/Footer";

// Компонент загрузки
const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-gradient-to-r from-blue-900 via-purple-900 to-blue-900 
                  flex items-center justify-center z-50">
    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-400"></div>
  </div>
);

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("authToken"));
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const userDataString = localStorage.getItem("userData");

    console.log("📌 Загружаем данные пользователя из localStorage:", userDataString);

    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        console.log("✅ Распарсенные данные пользователя:", userData);

        if (userData.role === "admin") {
          console.log("🎩 Пользователь — АДМИН");
          setIsAdmin(true);
        } else {
          console.log("👤 Пользователь — обычный");
        }
      } catch (error) {
        console.error("❌ Ошибка парсинга userData:", error);
        localStorage.removeItem("userData");
      }
    } else {
      console.log("❌ Нет данных о пользователе в localStorage");
    }
  }, []);

  useEffect(() => {
    const handleAuthChange = () => {
      setIsAuthenticated(!!localStorage.getItem("authToken"));
    };

    window.addEventListener("storage", handleAuthChange);
    return () => window.removeEventListener("storage", handleAuthChange);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  console.log("🔐 Авторизация:", isAuthenticated);
  console.log("🎩 Админ:", isAdmin);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Router>
      <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-900 to-blue-900">
        <Header isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />

        <main className="flex-grow">
          <Routes>
            <Route 
              path="/login" 
              element={isAuthenticated ? <Navigate to={isAdmin ? "/admin" : "/dashboard"} /> : <Login setIsAuthenticated={setIsAuthenticated} />} 
            />
            <Route 
              path="/register" 
              element={isAuthenticated ? <Navigate to={isAdmin ? "/admin" : "/dashboard"} /> : <Register />} 
            />
            
            {!isAdmin && (
              <>
                <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
                <Route path="/leads" element={isAuthenticated ? <Leads /> : <Navigate to="/login" />} />
                <Route path="/integration" element={isAuthenticated ? <Integration /> : <Navigate to="/login" />} />
              </>
            )}

            {isAdmin && (
              <Route path="/admin" element={isAuthenticated ? <AdminDashboard /> : <Navigate to="/login" />} />
            )}

            <Route 
              path="/" 
              element={isAuthenticated ? <Navigate to={isAdmin ? "/admin" : "/dashboard"} /> : <Navigate to="/login" />} 
            />
          </Routes>
          <Footer />
        </main>
      </div>
    </Router>
  );
};

export default App;
