import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Shield, Lock, ArrowRight, User } from "lucide-react";

const Login = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    console.log("📤 Отправка данных:", { username, password });

    try {
        const response = await fetch("https://trust-solution.com/api/users/login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username, password }),
        });

        const data = await response.json();

        console.log("📩 Ответ сервера (полный):", data);

        if (!response.ok) {
            console.error("❌ Ошибка ответа сервера:", data.message);
            throw new Error(data.message || "Ошибка входа");
        }

        if (!data.role) console.warn("⚠️ ВНИМАНИЕ: У пользователя отсутствует роль!");
        if (!data.apiKey) console.warn("⚠️ ВНИМАНИЕ: У пользователя отсутствует API-ключ!");

        // ✅ Гарантируем, что API-ключ и роль сохранятся в `localStorage`
        const userData = {
            username: data.username || username,
            role: data.role || "user",
            apiKey: data.apiKey || "MISSING_API_KEY"
        };

        console.log("💾 Данные перед сохранением:", userData);

        localStorage.setItem("authToken", data.token);
        localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("apiKey", data.apiKey || "MISSING_API_KEY"); // 🛠️ Исправлено

        console.log("✅ `localStorage` обновлён!");

        setIsAuthenticated(true);

        const redirectPath = data.role === "admin" ? "/admin" : "/dashboard";
        console.log("🔁 Редирект на:", redirectPath);
        navigate(redirectPath);

    } catch (err) {
        console.error("❌ Ошибка входа:", err.message);
        setError(err.message);
    }
};


  return (
    <div className="flex items-center justify-center min-h-[calc(100vh-4rem)] bg-gradient-to-b from-blue-900 via-purple-900 to-blue-900 p-4">
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 animate-pulse"></div>

      <div className="relative w-full max-w-md">
        <div className="flex flex-col items-center mb-8">
          <Shield className="w-16 h-16 text-blue-400 mb-4" />
          <h1 className="text-3xl font-bold text-white mb-2">Trust Solution</h1>
          <p className="text-blue-300">Your reliable partner</p>
        </div>

        <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 shadow-2xl">
          {error && <p className="text-red-400 text-center mb-4">{error}</p>}

          <form onSubmit={handleLogin} className="space-y-6">
            <div className="relative">
              <User className="absolute left-3 top-3.5 w-5 h-5 text-blue-300" />
              <input
                type="text"
                placeholder="Username"
                className="w-full bg-white/10 border border-blue-300/20 text-white placeholder-blue-300/50 
                         pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400/50 
                         focus:border-transparent transition-all duration-200"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>

            <div className="relative">
              <Lock className="absolute left-3 top-3.5 w-5 h-5 text-blue-300" />
              <input
                type="password"
                placeholder="Пароль"
                className="w-full bg-white/10 border border-blue-300/20 text-white placeholder-blue-300/50 
                         pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400/50 
                         focus:border-transparent transition-all duration-200"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 
                       text-white font-medium py-3 px-4 rounded-lg transition-all duration-200 
                       shadow-lg hover:shadow-blue-500/25 flex items-center justify-center space-x-2 
                       focus:outline-none focus:ring-2 focus:ring-blue-400/50"
            >
              <span>Войти</span>
              <ArrowRight className="w-5 h-5" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;