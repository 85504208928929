import React, { useState, useEffect } from "react";
import { Search } from "lucide-react";

const Leads = () => {
    const [leads, setLeads] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const authToken = localStorage.getItem("authToken");

    useEffect(() => {
        const fetchLeads = async () => {
            const apiKey = localStorage.getItem("apiKey"); // Получаем API-ключ
    
            if (!apiKey) {
                console.error("Ошибка: API-ключ отсутствует в localStorage");
                return;
            }
    
            console.log("Отправляем API-ключ:", apiKey);
    
            try {
                const response = await fetch(`https://trust-solution.com/api/leads`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "api-key": apiKey,  // Передаём API-ключ
                    },
                });
    
                const data = await response.json();
                console.log("Ответ от сервера:", data);
    
                if (response.ok) {
                    setLeads(data.leads);
                } else {
                    console.error("Ошибка загрузки лидов:", data.message);
                }
            } catch (error) {
                console.error("Ошибка запроса:", error);
            }
        };
    
        fetchLeads();
    }, []);
    

    const getStatusBadge = (status) => {
        const statusStyles = {
            new: "bg-green-500/20 text-green-400 border-green-500/20",
            active: "bg-blue-500/20 text-blue-400 border-blue-500/20",
            inactive: "bg-gray-500/20 text-gray-400 border-gray-500/20",
            pending: "bg-yellow-500/20 text-yellow-400 border-yellow-500/20"
        };

        const statusText = {
            new: "Новый",
            active: "Активный",
            inactive: "Неактивный",
            pending: "Ожидание"
        };

        return (
            <span className={`px-3 py-1 rounded-full text-sm border ${statusStyles[status] || "bg-gray-500/20 text-gray-400 border-gray-500/20"}`}>
                {statusText[status] || "Неизвестный"}
            </span>
        );
    };

    const filteredLeads = leads.filter(lead =>
        lead.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.phone.includes(searchTerm)
    );

    return (
        <div className="p-6 min-h-screen bg-gradient-to-b from-blue-900 via-purple-900 to-blue-900 mt-16">
            <div className="mb-8">
                <h1 className="text-3xl font-bold text-white mb-2">Лиды</h1>
                <p className="text-blue-300">Управление потенциальными клиентами</p>
            </div>

            <div className="flex flex-wrap gap-4 mb-6">
                <div className="relative flex-grow max-w-md">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-300 w-5 h-5" />
                    <input
                        type="text"
                        placeholder="Поиск по имени, email или телефону..."
                        className="w-full bg-white/10 border border-blue-300/20 text-white 
                                 placeholder-blue-300/50 pl-12 pr-4 py-3 rounded-lg
                                 focus:outline-none focus:ring-2 focus:ring-blue-400/50 
                                 focus:border-transparent transition-all duration-200"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>

            <div className="bg-white/10 backdrop-blur-lg rounded-xl overflow-hidden shadow-xl">
                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead>
                            <tr className="border-b border-blue-300/20">
                                <th className="text-left p-4 text-blue-300 font-medium">Имя</th>
                                <th className="text-left p-4 text-blue-300 font-medium">Фамилия</th>
                                <th className="text-left p-4 text-blue-300 font-medium">Email</th>
                                <th className="text-left p-4 text-blue-300 font-medium">Телефон</th>
                                <th className="text-left p-4 text-blue-300 font-medium">Страна</th>
                                <th className="text-left p-4 text-blue-300 font-medium">Статус</th>
                                <th className="text-left p-4 text-blue-300 font-medium">Дата создания</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredLeads.length > 0 ? (
                                filteredLeads.map((lead) => (
                                    <tr key={lead._id} className="border-b border-blue-300/10 hover:bg-white/5 transition-colors duration-150">
                                        <td className="p-4 text-white">{lead.firstName}</td>
                                        <td className="p-4 text-white">{lead.lastName}</td>
                                        <td className="p-4 text-blue-300">{lead.email}</td>
                                        <td className="p-4 text-blue-300">{lead.phone}</td>
                                        <td className="p-4 text-blue-300">{lead.country}</td>
                                        <td className="p-4">{getStatusBadge(lead.status)}</td>
                                        <td className="p-4 text-blue-300">{new Date(lead.createdAt).toLocaleDateString()}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="p-4 text-center text-blue-300">Лиды не найдены</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Leads;
