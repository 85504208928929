import React, { useState, useEffect } from "react";
import { Users, TrendingUp, Activity } from "lucide-react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";

const Dashboard = () => {
    const [stats, setStats] = useState({
        totalLeads: 0,
        newLeads: 0,
        processedLeads: 0,
        conversionRate: 0,
    });

    const [conversionData, setConversionData] = useState([]);
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");

    useEffect(() => {
        const fetchStats = async () => {
            const apiKey = localStorage.getItem("apiKey");
            console.log("Отправленный API-ключ:", apiKey);

            if (!apiKey) {
                console.error("Ошибка: API-ключ отсутствует в localStorage!");
                return;
            }

            try {
                const response = await fetch("https://trust-solution.com/api/leads", {
                    headers: { "api-key": apiKey },
                });

                console.log("Ответ от сервера:", response.status, response.statusText);

                if (!response.ok) {
                    const errorText = await response.text();
                    console.error("Ошибка ответа сервера:", errorText);
                    throw new Error(`Ошибка загрузки данных: ${response.status} ${response.statusText}`);
                }

                const data = await response.json();
                console.log("Полученные данные:", data);

                setStats({
                    totalLeads: data.totalLeads,
                    newLeads: data.newLeads,
                    processedLeads: data.processedLeads,
                    conversionRate: data.conversionRate,
                });

                // Данные для графика
                setConversionData([
                    { name: "Всего лидов", value: data.totalLeads },
                    { name: "Новые лиды", value: data.newLeads },
                    { name: "Обработанные лиды", value: data.processedLeads },
                ]);
            } catch (error) {
                console.error("Ошибка загрузки данных:", error);
            }
        };

        fetchStats();
    }, []);

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-900 via-purple-900 to-blue-900 p-6 mt-16">
            {/* Заголовок */}
            <div className="mb-8">
                <h1 className="text-3xl font-bold text-white mb-2">
                    Добро пожаловать, {userData.fullName || "Пользователь"}
                </h1>
                <p className="text-blue-300">
                    {userData.companyName ? `${userData.companyName} • ` : ""} Панель управления лидами
                </p>
            </div>

            {/* Статистика */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                <StatCard 
                    icon={Users} 
                    title="Всего лидов" 
                    value={stats.totalLeads} 
                    trend="+10%" 
                    colorClass="bg-gradient-to-r from-blue-500 to-purple-500"
                />
                <StatCard 
                    icon={TrendingUp} 
                    title="Новые лиды" 
                    value={stats.newLeads} 
                    trend="+8.2%" 
                    colorClass="bg-gradient-to-r from-green-500 to-emerald-500"
                />
                <StatCard 
                    icon={Activity} 
                    title="Обработанные лиды" 
                    value={stats.processedLeads} 
                    trend="+15.3%" 
                    colorClass="bg-gradient-to-r from-pink-500 to-rose-500"
                />
            </div>

            {/* График лидов */}
            <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
                <h2 className="text-xl font-bold text-white mb-6">Количество лидов</h2>
                <div className="h-80">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={conversionData}>
                            <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
                            <XAxis dataKey="name" stroke="#93c5fd" />
                            <YAxis stroke="#93c5fd" />
                            <Tooltip 
                                contentStyle={{ 
                                    backgroundColor: 'rgba(30, 41, 59, 0.9)',
                                    border: 'none',
                                    borderRadius: '8px',
                                    color: '#fff'
                                }}
                            />
                            <Legend />
                            <Bar 
                                dataKey="value" 
                                fill="url(#colorGradient)" 
                                radius={[4, 4, 0, 0]}
                            />
                            <defs>
                                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="#3b82f6" stopOpacity={1} />
                                    <stop offset="100%" stopColor="#8b5cf6" stopOpacity={1} />
                                </linearGradient>
                            </defs>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

// Компонент карточки статистики
const StatCard = ({ icon: Icon, title, value, trend, colorClass }) => (
    <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6 flex flex-col">
        <div className="flex items-center justify-between mb-4">
            <div className={`p-3 rounded-lg ${colorClass}`}>
                <Icon className="w-6 h-6 text-white" />
            </div>
            {trend && (
                <span className="text-green-400 text-sm flex items-center">
                    <TrendingUp className="w-4 h-4 mr-1" />
                    {trend}
                </span>
            )}
        </div>
        <h3 className="text-blue-300 text-sm mb-1">{title}</h3>
        <p className="text-white text-2xl font-bold">{value}</p>
    </div>
);

export default Dashboard;
